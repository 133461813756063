export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: [String, Number],
      required: false,
    },
    percentage: {
      type: Number,
      required: false,
    },
    colorClass: {
      type: String,
      required: false,
    },
    percentageColorClass: {
      type: String,
      required: false,
    },
    customHeadingRightText: {
      type: String,
      default: '',
    },
    showProgressBar: {
      type: Boolean,
      default: true,
    },
    titlePaddinBottomStyle: {
      type: String,
      default: '',
      required: false,
    },
  },
  name: 'StatCard',
  components: { },
  data: function () {
   return {
   }
  },
  methods: {

  },
  created () {

  },
}
